<template>
  <!-- BD信息维护列表 -->
  <div class="container" style="margin-top: 15px;">
    <div class="dataOper">
      <div class="OperInput">
        <div class="head">
          <div class="search_box">
            <div class="search_main">
              <div class="status" style="width: 356px;">
                <div>时间</div>
                <el-date-picker v-model="datatime" type="datetimerange" range-separator="至"
                  :start-placeholder="startTime" :end-placeholder="endTime" format="yyyy-MM-dd" :clearable="true"
                  @change="datebtn">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <div class="dataTable">
        <h1>店铺维护数据</h1>
        <el-table style="font-family: PingFangRoutine" :data="tableData" tooltip-effect="dark"
          :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }">
          <el-table-column prop="name" label="BD姓名"> </el-table-column>
          <el-table-column prop="storeNum" label="维护店铺数">
            <template slot-scope="scope">
              <span @click="storebtn(scope.row.id)" style="color:blue;">
                {{scope.row.storeNum}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="goodsNum" label="商品维护数">
            <template slot-scope="scope">
              <div @click="goodsbtn(scope.row.id)" style="color:blue;">
                {{scope.row.goodsNum}}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1"
            :page-size="queryinfo.pageSize" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
    <!-- 编辑备注弹框 -->
  </div>
</template>
<script>
  import {
    bdList,
    bdAdd,
    bdEdit,
    bdDelete,
    bdEditor,
    bdGetInfo,
    bdGetMaintain
  } from "@/api/bd";
  import "@/font-style/font.css";
  export default {
    data() {
      return {
        queryinfo: {
          currPage: 1, //当前页数
          endTime: "", //结束时间
          pageSize: 10, //页面容量
          startTime: "",
        },
        startTime: "", //默认开始时间
        endTime: "", //默认结束时间
        createTime: "",
        datatime: [],
        newStarTime: '',
        newEndTime: '',
        total: 0,
        tableData: [], // 表格数据
      };
    },
    created() {
      this.date()
    },
    methods: {
      //获取当前时间
      date() {
        let myDate = new Date();
        let n = myDate.getFullYear();
        let y = myDate.getMonth() + 1;
        let r = myDate.getDate();
        let s = myDate.getHours();
        let f = myDate.getMinutes();
        let m = myDate.getSeconds()
        this.newStarTime = n + '-' + y + '-' + '1' + " " + '0:00:00' //默认开始时间
        this.newEndTime = n + '-' + y + '-' + r + " " + '23:59:59' //默认结束时间
        this.queryinfo.endTime = this.newEndTime, //请求参数开始时间
          this.queryinfo.startTime = this.newStarTime //请求参数结束时间
        this.startTime = n + '-' + y + '-' + '1' //默认页面显示开始时间
        this.endTime = n + '-' + y + '-' + r //默认页面显示结束时间
        this.list()
      },
      tableHeaderColor({
        rowIndex
      }) {
        if (rowIndex === 0) {
          return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
        }
      },

      //BD员工列表
      list() {
        if (!this.datatime) {
          this.datatime = []
        }
        bdGetMaintain(this.queryinfo).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total * 1;
        })
      },
      storebtn(id) {
        this.$router.push({
          path: '/bd/maintain/store/list',
          query: {
            id: id,
            endTime: this.queryinfo.endTime,
            startTime: this.queryinfo.startTime
          }
        })
      },
      goodsbtn(id) {
        this.$router.push({
          path: '/bd/maintain/goods/list',
          query: {
            id: id,
            endTime: this.queryinfo.endTime,
            startTime: this.queryinfo.startTime
          }
        })
      },
      // 分页
      handleCurrentChange(val) {
        this.queryinfo.currPage = val;
        this.list();
      },
      datebtn() {
        this.total = 0
        if (!this.datatime) { // 如果被清空，手动给它赋值为[]
          this.datatime = []
          this.queryinfo.startTime = this.newStarTime
          this.queryinfo.endTime = this.newEndTime
        }
        if (typeof this.datatime !== 'string' && this.datatime.length !== 0) {
          let time1 = this.datatime[0]
          let newtime1 = time1.getTime()
          let item1 = new Date(newtime1)

          let time2 = this.datatime[1]
          let newtime2 = time2.getTime()
          let item2 = new Date(newtime2)

          let y1 = item1.getFullYear()
          let m1 = item1.getMonth() + 1
          let d1 = item1.getDate()

          let y2 = item2.getFullYear()
          let m2 = item2.getMonth() + 1
          let d2 = item2.getDate()
          let timeStr1 = y1 + '-' + m1 + '-' + d1 + ' ' + '0:00:00'
          let tiemStr2 = y2 + '-' + m2 + '-' + d2 + ' ' + '23:59:59'
          this.queryinfo.startTime = timeStr1,
          this.queryinfo.endTime = tiemStr2
        }
        this.list()
      }
    },
  };
</script>
<style lang="less" scoped>
  @import "../../../assets/css/common.less";
  .head {
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 18px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  }

  #btn {
    width: 90px;
    margin-right: 50px;
  }

  /deep/ #remark {
    height: 100px;
    width: 260px;
  }

  .el-textarea {
    height: 100px;
  }

  /deep/ .cell {
    text-align: center;
  }

  /deep/ .OperInput {
    display: flex;
    margin-top: -5px;
  }

  h1 {
    margin-bottom: 20px;
  }
</style>
